export default function KeepInView (elements) {
  return {
    timeout: undefined,
    fixed: false,
    mobileToggle: null,

    init () {
      // when the mobile menu is toggled, unfix...
      this.mobileToggle = document.getElementById('showMenu');
      this.mobileToggle.addEventListener('click', this.unfix.bind(this));

      if (elements === undefined || elements.length === 0)
        return;

      const defaultCTA = elements[0];

      if (defaultCTA === undefined || defaultCTA.cloneNode === undefined)
        return;

      this.container = this.createContainer();

      document.addEventListener(
        'scroll',
        (e) => {
          clearTimeout(this.timeout);

          this.unfix();
          // if the mobile menu is open, bail...
          if (this.mobileToggle.checked) return;

          this.timeout = setTimeout(() => {
            for (let i = elements.length - 1; i >= 0; i--)
              if (this.inViewport(elements[i]))
                return;

            if (!this.fixed)
              this.fix(elements[0]);
          }, 100);
        },
        true
      );
    },

    unfix () {
      this.fixed = false;
      this.container.className = '';
      this.container.innerHTML = '';
    },

    fix (el) {
      this.fixed = true;
      this.container.innerHTML = '';
      this.container.className = 'animation-fadein';
      this.container.appendChild(el.cloneNode(true));

      if (!this.container.parentNode)
        document.body.appendChild(this.container);
    },

    createContainer () {
      const id = 'keep-in-view';
      const container = document.getElementById(id) || document.createElement('div');

      container.id = id;

      return container;
    },

    inViewport (el) {
      let top = el.offsetTop;
      let left = el.offsetLeft;
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
      );
    }
  };
}
